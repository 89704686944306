import {
    createStore,
    // applyMiddleware,
} from 'redux'
import PhrfToolsReducers from './Reducers'
// import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'


const loadState = () => {
    try {
        const serializedState = window.localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        var restoredState = JSON.parse(serializedState)
        // console.log("restoredState", restoredState);
        return restoredState;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        window.localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

// const loggerMiddleware = createLogger();
const persistedState = loadState();

const store = createStore(
    PhrfToolsReducers, 
    persistedState//, 
    // applyMiddleware(thunkMiddleware),
    // process.env.NODE_ENV === "production" ? 
    //     applyMiddleware(thunkMiddleware) :
    //     applyMiddleware(thunkMiddleware, loggerMiddleware)
);

store.subscribe(() => {
    saveState(store.getState());
  });

export default store;