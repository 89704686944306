// Reference: http://www.cic.org.ar/subpaginas/reglamentophrf.pdf
  
const valoresHelicePF = {
    "F2P":0.053,
    "F3P":0.068,
    "ORI":0.024,
    "PLE":0.019,
    "S/H":0.0,
}

const tiposMotorEW = {
    "externo": 6,
    "interno": 9,
    "sinmotor": 0,
}

export const getTCF = (rating) =>{
    var TCF = (Math.sqrt(rating) + 2.6) / 10
    return TCF
}

export const getRatingFromTCF = (tcf) => {
    var rating = Math.pow((tcf * 10.0) - 2.6, 2);
    return rating;
}


const getAreaTriangulo = (a, b, c) => {
    var s = 0.5*(a+b+c);
    return Math.sqrt( s * (s-a)*(s-b)*(s-c))
}
  
  
const filterBoatClass = (boatClass) => {
    var boatClassClean = boatClass.replace(/[^A-Za-z0-9]/g, '')
    // console.log(`${boatClass} => ${boatClassClean}`)
    if(boatClassClean === "MALBEC240"){
      return "MYSTIC 235"
    }else if (boatClassClean === "PLENAMAR24"){
        return "PLENAM.24"
    }else if (boatClassClean === "Z28DS"){
        return "Z 28 DS"
    }else if (boatClassClean === "MITIARO258"){
        return "MITIARO258"
    }

    return boatClass;
}

export const getDEForBoatClass =  (boatClass, re) => {
    var reClass = re.filter( bc => bc.Tipo.trim().toUpperCase() === boatClass.trim().toUpperCase())
    if(reClass && reClass.length > 0){
        return reClass[0]
    }
    return null
}

export const getRatingEntradaParaClase =  (boatClass, ratings) => {
    // console.log("boatClass", boatClass);
    // console.log("ratings", ratings);
    var reClass = ratings.filter( bc => bc!= null && bc.name.trim().toUpperCase() === boatClass.trim().toUpperCase() && bc.sail === "*")
    if(reClass && reClass.length > 0){
        return parseFloat(reClass[0].rating); 
    }
    return null
}

function naiveRound(num, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}

export const calcRating =  ({
    boatClassName,
    LOA,
    HLU,
    LP,
    J,
    E,
    P,
    STL,
    SHW,
    SL,
    SHWA = 0,
    SLU = 0,
    SLE = 0,
    MHW,
    MTW,
    MUW,
    MGY = 0,
    EY,
    PY,
    // COEFICIENTE_EW,
    tipoMotor,
    tipoHelice,
    // PF,
    usaTangon,
    usaBotalon,
    velaDeProaDeEnrollar,
    mayorDeEnrollar,
    CMAX,
    CMIN,
    tipoQuilla,
    WD
  }, ratings, dimensionesEstandar) => {
    const boatClass = filterBoatClass(boatClassName)
    var reBoat = getDEForBoatClass(boatClass, dimensionesEstandar);
    console.log("reBoat", reBoat);
    console.log("HLU", HLU);
    if(reBoat === null){
      return {Estado: "Complete todos los campos para obtener el rating"};
    }
    var LOAS = parseFloat(reBoat.LOA);
    // var RE = 24.0//LOAS * 3.28084
    var RE = getRatingEntradaParaClase(boatClass, ratings);
    if(RE === null){
        return {Estado: "Complete todos los campos para obtener el rating"};
    }
    // Medidas Standard:
    // var FLS = parseFloat(reBoat.FL);
    var HLUS = parseFloat(reBoat.HLU);
    var BPROAS = parseFloat(reBoat.BProa);
    var STLS = parseFloat(reBoat.STL);
    var SHWS = parseFloat(reBoat.SHW);
    var SLS = parseFloat(reBoat.SL);
  
    var KL = parseFloat(reBoat.KL);
    var PS = parseFloat(reBoat.P);
    var ES = parseFloat(reBoat.E);
    var EYS = parseFloat(reBoat.EY);
    var PYS = parseFloat(reBoat.PY);
    var hecliceS = reBoat.Helice;
    var PFS = valoresHelicePF[hecliceS];
    var COEFICIENTE_EW = tiposMotorEW[tipoMotor];
    var DK = 0; // Esto no se de donde se obtendria... ¿quisas de la DJM ?
    var WDS = parseFloat(reBoat.WD);
    var CMINS = parseFloat(reBoat.CMIN);
    var CMAXCMINS = parseFloat(reBoat["CMAX-CMIN"]);
    var CMAXS = CMINS + CMAXCMINS;
  
    // var JS = parseFloat(reBoat.J) || J;
    var JS = parseFloat(reBoat.BProa) || J;
    // var LPS = parseFloat(reBoat.LP) || LP;
    var LPS = parseFloat(reBoat.LPG) || LP;
  
    // 2) Fl o Factor de eslora
    var LOAC = Math.abs( (LOA - LOAS) / LOA ) < 0.02 ? LOAS : LOA;
    var KL_OK = (LOA < LOAS) ? KL*0.5 : KL;
    var FLE = (1.13 * KL_OK * (LOAC - LOAS)) / RE;
    console.log("FLE", {LOA,LOAS,KL,LOAC,RE});
    
    // 3) FV o Factor de vela
    
    // var ICALCS = Math.sqrt(FLS * FLS - JS * JS); 
    var ICALCS = Math.sqrt( ((1.04 * HLUS) * (1.04 * HLUS)) - (JS * JS)  );

    var HPS = STLS + (2 * BPROAS) + (2 * LPS) + (1.703 * SHWS);
  
    var VPS = ICALCS + SLS;
  
    var SPS = (0.0457 * HPS * VPS) - (0.0031 * HPS * HPS);
  
    var SMS = 0.7 * (0.6404 * PS * ES); 
  
    var SYS = (0.1275 * PYS * PYS * EYS * EYS) / (SMS + (0.5 * PYS * EYS)); 
  
    var SVS = Math.sqrt(SPS + SMS + SYS);
  
    // var ICALC = Math.sqrt(FL * FL - J * J);
    // var ICALC = Math.sqrt(FLE * FLE - J * J);

    var ICALC = null;
    if(HLU >= (0.9*HLUS)){
      ICALC = Math.sqrt( (1.04  * HLU * 1.04 * HLU) - (J*J) );
    }else if ( HLU <= (0.85*HLUS) ){
      ICALC = Math.sqrt( (1.04  * HLUS * 1.04 * HLUS) - (J*J) );
    }else{
      ICALC = Math.sqrt( (1.04 * 0.9 * HLUS * 1.04 * 0.9 * HLUS) - (J*J) );
    }
  
  //STLC será el mayor de STL o J; LPC será el mayor de LP o J; SHWC será el mayor de SHW o 1.5 x J; 
    var STLC = Math.max(STL, J);
    var LPC = Math.max(LP, J);
    
    var areaSimetrico = SL > 0 && SHW > 0 && getAreaTriangulo(SL/2.0, SL/2.0, SHW);
    var areaAsimetrico = SLU > 0 && SLE > 0  && SHWA > 0 && getAreaTriangulo(SLU/2.0, SLE/2.0, SHWA);
    
    // var SHWMod = Math.max(SHW, SHWA)
    var SHWMod = areaSimetrico > areaAsimetrico ? SHW : SHWA;
    var SHWC = Math.max( SHWMod, 1.5 * J );
  
    // var SLMod = Math.max(SL, SLU)
    var SLMod = areaSimetrico > areaAsimetrico ? SL : SLU;
    var SLC = Math.max(  SLMod  , 0.9 * ICALC);
  
    var HP = STLC + (2 * J) + (2 * LPC) + (1.703 * SHWC);
  
    var VP = ICALC + SLC;
  
    var SP = (0.0457 * HP * VP) - (0.0031 * HP * HP); // DIFERENCIA
    
    var HPss = STLC + (2 * J) + (2 * LPC) + (1.7 * 1.8 * J);
    var VPss = 2 * ICALC;
    var SPss =(0.0457 * HPss * VPss) - (0.0031 * HPss * HPss);
  
  
    var SM = ( (0.1684 * E) + (0.2582 * MHW) + (0.2341 * MTW) + (0.105 * MUW)) * P;
    var SY = (0.1275 * EY * EY * PY * PY) / (SM + (0.5 * PY * EY));
  
    var SV = Math.sqrt(SP + SM + SY);
  
    var SVss = Math.sqrt(SPss + SM + SY);
  
    var FV = (2.4 * (SV - SVS)) / RE;
  
    var FVss = (2.4 * (SVss - SVS)) / RE;
  
    //FC o Factor de Calado
    var RD = 0;
    var RDS = 0;
  
    var CD = CMAX - CMIN;
    var CDS = CMAXS - CMINS;
    
    if (tipoQuilla === "FIJA") {
      RD = CMIN;
      RDS = CMINS;
    } else if (tipoQuilla === "SQLL") {
      RD = CMIN + 0.5 * CD;
      RDS = CMINS + 0.5 * CDS;
    } else if (tipoQuilla === "ORZA") {
      RD = CMIN + CD;
      RDS = CMINS + CDS;
    } else if (tipoQuilla === "ALAR") {
      RD = CMIN + 0.3 * WD;
      RDS = CMINS + 0.3 * WDS;
    }
  
    var DB = 3.02 * Math.sqrt(Math.sqrt(LOAS)) - 3.58;
    var FC = (0.16 * LOA * (RD * RD - RDS * RDS)) / (DB * DB) / RE;
  
    //FD o Factor de helice
    var PF = tipoMotor === "interno" ? valoresHelicePF[tipoHelice] : 0.0;    
    var FH = (PFS - PF) / (1 - PFS);
  
    //FD o Factor de desplazamiento
    var EW = COEFICIENTE_EW * LOAS;
  
    // var FD = naiveRound((-0.004 * (DK + EW)) / (LOAS * LOAS), 3);
    var FD = (-0.004 * (DK + EW)) / (LOAS * LOAS);
  
    //FX o Factor de extras
    const Fx = usaTangon && usaBotalon ? 0.01 : 0;
    // console.log("Fx", Fx)
  
    //Factor de Enrolladores
    var FEG = velaDeProaDeEnrollar && LP > 1.3 * J ? 0.0099 : 0;
    var FEM = mayorDeEnrollar ? 0.0097 : 0;
    var FE = FEG + FEM;
    // console.log("FE", FE)
  
    // var Fi = naiveRound(1 / 1.2,3);
    var Fi = 1 / 1.2;
  
    var FMod = 1 + FLE + FV + FC + FH + FD + FE + Fx; //
    var FModss = 1 + FLE + FVss + FC + FH + FD + FE + Fx; //
  
    var RatingConSpi = RE * FMod * Fi;
    var RatingSinSpi = RE * FModss * Fi * 0.965;
    var response= {
        "Rating Con Spi": roundCIC(RatingConSpi),
        "Rating Sin Spi": roundCIC(RatingSinSpi),
        "Rating Con Spi (2)": Math.round(RatingConSpi*10)/10.0,
        "Rating Sin Spi (2)": Math.round(RatingSinSpi*10)/10.0,
        "Rating Con Spi (3)": RatingConSpi,
        "Rating Sin Spi (3)": RatingSinSpi,
    //   "Ratings": {
    //     R: roundCIC(RE * FMod * Fi),
    //     Rss: roundCIC(RE * FModss * Fi * 0.965)
    //   },
      "Parametros": {
        RE,
        FMod,
        FModss,
        Fi
      },
      "Factores": {
        "Factores Finales": {
          FLE,
          FV,
          FVss,
          FC,
          FH,
          FD,
          FE,
          Fx,
          Fi
        },
        "1 - rating": {
          Rating: RE * FMod * Fi,
          RatingSinSpinnaker: RE * FModss * Fi * 0.965,
          RE,
          FMod,
          FModss,
          Fi
        },
        "2 - eslora": {
          FLE,
          LOA,
          LOAC,
          LOAS,
          KLS:KL,
          KL:KL_OK,
        },
        "3a - velas": {
          FV,
          FVss,
          ICALCS,
          HPS,
          VPS,
          SPS,
          SMS,
          SYS,
          SVS,
          // areaSimetrico,areaAsimetrico,
          ICALC,
          STLC,
          STL,
          J,
          LP,
          LPC,
          SHWC,
          SLC,
          HP,
          VP,
          SP,
          SM,
          SY,
          SV,
          SVss,
          SPss,
          HPss,
          VPss,
        },
        "3b - calado": {
          FC,
          DB,
          RD,
          RDS,
          CD,
          CDS,
        },
        "4 - helice": {
          FH,
          PFS,
          PF
        },
        "5 - desplazamiento": {
          COEFICIENTE_EW,
          FD,
          DK,
          EW
        },
        "6 - extras": {
          Fx
        },
        "7 - enrollador": {
          FE,
          FEG,
          FEM
        }
      },
      "Medidas Estandar":
      {
        HLUS,BPROAS,STLS,SHWS,SLS,KL,PS,ES,EYS,PYS,hecliceS,PFS,COEFICIENTE_EW,DK,WDS,CMINS,CMAXS,JS,LPS,LOAS   
      },
      "Medidas Ingresadas":
      {
        boatClassName,LOA,HLU,LP,J,E,P,STL,SHW,SL,SHWA,SLU,SLE,MHW,MTW,MUW,MGY,EY,PY,tipoMotor,tipoHelice,usaTangon,usaBotalon,velaDeProaDeEnrollar,mayorDeEnrollar,CMAX,CMIN,tipoQuilla,WD
      }
    };
    // console.log("RATING:", response);
    return response;
  };
  
  

function roundCIC(value){
  return naiveRound(value, 1); 
  return Math.ceil(value * 10.0) / 10.0;
}