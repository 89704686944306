import * as React from 'react';
import { H2 } from '../HTML/Heading';
import {  Button, Grid, Input } from '@mui/material';
import Panel from '../Panel';
import TextoLegal from '../TerminosCondicionesPoliticas/TextoLegal';
import { AppContext } from '../../providers/AppContext';

export default function AceptarTyCParaContinuar(props){
    const {children}=props;
    const {tycAccepted, setTyCAccepted} = React.useContext(AppContext);
    return <>
        {tycAccepted === false && <>
            <Grid item xs={12}>
                <Panel>
                    <H2>Calculadora de ratings</H2>
                    <p>Antes de utilizar la calculadora de ratings, debes aceptar los terminos, condiciones y politicas de privacidad.</p>
                    <TextoLegal />
                    
                    <br/>
                    <div>
                        <Button variant="contained" onClick={() => setTyCAccepted(true)}>He leido y acepto los terminos, condiciones y responsabilidad.</Button>
                    </div>
                    
                </Panel>
            </Grid>
        </>}
        {tycAccepted !== false && <>{children}</>}       
    </>
}