import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {colors} from '@mui/material'
import { MainHeader } from './MainHeader';
import { MainDrawer } from './MainDrawer';
import { MainContentContainer } from './MainContentContainer';




const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: colors.red.A400,
    },
  },
});


function DashboardContent() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MainHeader open={open} toggleDrawer={toggleDrawer}/>
        <MainDrawer open={open} toggleDrawer={toggleDrawer}/>
        <MainContentContainer />
      </Box>
    </ThemeProvider>
  );
}


export default function MainLayout() {
  return <DashboardContent />
}

