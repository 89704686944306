import * as React from 'react';
import { List, ListItemButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItemText from '@mui/material/ListItemText';

export function DetallesDeRartingVertical({rating}){
    
    return <><DrawListRecursively data={rating}/></>;

}


            // <DrawObjectRecursively data={rating} />


function DrawListRecursively({data}){
    

    return (<List>
        {Object.keys(data).map((key, i) => {
            var value = data[key];
            if(typeof(value) === "undefined" || value === null)
                return <></>;
            if( ["string", "number"].indexOf(typeof(value)) !== -1  ){
                return <ListItemForSimpleKeyValue key={i} title={key} value={typeof value === "number" ? drawNiceNumber(value) : value}/>;
            }else if(typeof(value) === "object" && Object.keys(value).length > 0){
                return <ListItemForNewSet key={i} title={key} data={value} />
            }else{
                return <></>;
            }
        })}
    </List>);
}

function drawNiceNumber(number){
    var coeficient = 10000000.0;
    return   parseInt(number * coeficient) / coeficient;
}

function ListItemForSimpleKeyValue({title, value}){
    return <ListItemButton><ListItemText><b>{title}:</b>&nbsp;{value.toString()}</ListItemText></ListItemButton>
}

function ListItemForNewSet({title, data}){
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };
    return (<>
        <ListItemButton onClick={handleClick}><ListItemText>{title}</ListItemText>{open ? <ExpandLess /> : <ExpandMore />}</ListItemButton>
        <Collapse  in={open} timeout="auto" unmountOnExit style={{marginLeft:10}}><DrawListRecursively data={data}/></Collapse>
    </>)
}

// function DrawRating    

// function DrawObjectRecursively({data}){
//     return (<dl>
//         {Object.keys(data).map(key => {
//             var value = data[key];
//             if(typeof(value) === "undefined" || value === null)
//                 return <></>;
//             if( ["string", "number"].indexOf(typeof(value)) !== -1  ){
//                 return <><dt>{key}:</dt><dd>{value.toString()}</dd></>
//             }else if(typeof(value) === "object" && Object.keys(value).length > 0){
//                 return <><dt>{key}:</dt><dd><DrawObjectRecursively data={value}/></dd></>    
//             }else{
//                 return <></>;
//             }
//         })}
//     </dl>);
// }