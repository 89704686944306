import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Panel from '../Panel';
import { useHistory } from 'react-router-dom';
import { H1 } from '../HTML/Heading';


export function DashboardPage() {
    var history = useHistory();
    return (<>
    
        <Panel>
        <H1>Bienvenido!</H1>
            <p style={{textAlign:"center"}}>
              En esta web puede utilizar las dos herramientas creadas para PHRF: la calculadora de ratings y el formulario de pago/paga.
            </p>
        </Panel>
          
        <Container sx={{ py: 8 }} maxWidth="md">

          <Grid container spacing={4}>
            
          <Grid item xs={12} md={6}>
                <Card
                  sx={{  display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    image="/images/calculator.jpg"
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Calculadora de rating
                    </Typography>
                    <Typography>
                      Calcula cual será tu rating para las medidas de tu embarcación.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => history.push("/calcula-rating")}>Calcular</Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item  xs={12} md={6}>
                <Card
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    image="/images/timing.jpg"
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Pago / Paga
                    </Typography>
                    <Typography>
                      Compara tu pérformance contra la otras embarcaciones.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => history.push("/comparaciones")}>Comparar</Button>
                  </CardActions>
                </Card>
              </Grid>

          </Grid>
        </Container>
      
 </> );
}