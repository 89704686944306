import {
    combineReducers
} from 'redux'
import {actions} from './Actions';


const dimensionesEstandar = (state = [], action) => {
    switch (action.type) {
        case actions.SET_DIMENSIONES_ESTANDAR:
            return [...action.dimensionesEstandar];
        default:
            return state;
    }
}

const ratings = (state = [], action) => {
    switch (action.type) {
        case actions.SET_RATINGS:
            return [...action.ratings];
        default:
            return state;
    }
}

const syncDate = (state = false, action) => {
    switch (action.type) {
        case actions.SET_SYNC_DATE:
            return action.syncDate;
        default:
            return state;
    }  
}



// const barcoActual = (state = {}, action) => {
//     switch (action.type) {
//         case actions.SET_BARCO_DECLARADO:
//             return action.ratings;
//         default:
//             return state;
//     }
// }

const PhrfToolsReducers = combineReducers({
    dimensionesEstandar,
    ratings,
    syncDate
})

export default PhrfToolsReducers;