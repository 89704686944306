import React from 'react';
import { useDispatch } from 'react-redux';
import { loadDimensionesEstandar } from './loadDimensionesEstandar';
import { loadRatings } from './loadRatings';

export default function InitialDataLoader({children}){
    const dispatch = useDispatch();
    React.useEffect( () => {
        async function LoadInitialDataAsync(){
            await Promise.allSettled([
                loadRatings(dispatch),
                loadDimensionesEstandar(dispatch)
            ]);
        }
        LoadInitialDataAsync();
    });
    return (<>{ children }</>);

}