import * as React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export function FindBoatInput(props){
    var {
        onBoatFound,
        label = "Nombre de barco",
        id = "find-boat-by-name",
        width = null,
        nextElementWithFocus = null,
        cleanOnSelected = false,
    } = props;
    var ratings = useSelector( store => store.ratings);
    var [options, setOptions] = React.useState([]);
    var [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');

    const filterOptions = (event, newInputValue) => {
        setInputValue(newInputValue);
        if(newInputValue.length < 3 ){
            setOptions([]);
            return;
        }
        var newOptions = ratings.filter(r => 
            r.name.toLowerCase().indexOf(newInputValue.toLowerCase()) !== -1 || 
            r.sail.toLowerCase().indexOf(newInputValue.toLowerCase()) !== -1 || 
            r.class.toLowerCase().indexOf(newInputValue.toLowerCase()) !== -1
        );
        setOptions(newOptions.slice(0,20));
    }

    const onBoatFoundHandler = (event, newValue) => {
        if(!newValue)   return;
        if(typeof(onBoatFound) === 'function')
            onBoatFound(newValue)
        if(cleanOnSelected){
            setValue(null);
            setInputValue("");
            document.getElementById(id).blur();
        }else{
            console.log("newValue", newValue);
            console.log("event:", event.target.value);
            setValue(newValue);
            // setInputValue(newValue);
        }
        if(nextElementWithFocus !== null){
            setTimeout(() => document.getElementById(nextElementWithFocus).focus(), 200)
        }
    }
    const getOptionLabel = (option) => `${option.name} - Vela Nº ${option.sail} - Tipo ${option.class} - Serie ${option.serie}`
    return (
        <Autocomplete
            id={id}
            options={options}
            autoHighlight
            onChange={onBoatFoundHandler}
            onInputChange={filterOptions}
            size="small"
            value={value}
            width={width}
            inputValue={inputValue}
            filterOptions={(x) => x}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option) => (
                <Box component="li" {...props}>{getOptionLabel(option)}</Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText="Escriba el nombre, tipo o numero de vela del barco"
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                    }}
                />
            )}
        />
      );
}