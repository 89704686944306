export const actions = {
    SET_DIMENSIONES_ESTANDAR:"SET_DIMENSIONES_ESTANDAR",
    SET_RATINGS:"SET_RATINGS",
    SET_BARCO_ACTUAL:"SET_BARCO_ACTUAL",
    SET_BARCO_DECLARADO:"SET_BARCO_DECLARADO",
    SET_SYNC_DATE:"SET_SYNC_DATE"
}
export const setSyncDate = (syncDate) => {
    return {
        type:actions.SET_SYNC_DATE,
        syncDate
    }
}
export const setDimensionesEstandar = (dimensionesEstandar) => {
    return {
        type:actions.SET_DIMENSIONES_ESTANDAR,
        dimensionesEstandar
    }
}

export const setRatings = (ratings) => {
    return {
        type:actions.SET_RATINGS,
        ratings
    }
}
