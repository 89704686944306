import * as React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/styles';


const StyledTextField = styled(TextField)({
    // fontSize:12,

})


export default function NumberInput(props){
    var {
        id,
        label,
        variant,
        size = "small",
        fullwith = "false",
        helperText,
        onChange,
        defaultValue,
        value,
    } = props;
    return <StyledTextField 
                id={id} 
                label={label} 
                variant={variant} 
                size={size} 
                fullwith={fullwith}
                helperText={helperText}
                type="number"
                onChange={onChange}
                defaultValue={defaultValue}
                value={value}
            />
}