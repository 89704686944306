import * as React from 'react';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { MainNavigationSwitch } from './MainNavigationSwitch';


export function MainContentContainer(props){

    return <>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar className="no-print"/>
          <Container maxWidth="fluid" sx={{ mt: 4, mb: 4 }}>
            <MainNavigationSwitch />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>


    
    </>

}

        

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} >
      <Link color="inherit" href="#/terminos-condiciones">
        Terminos y Condiciones
      </Link>
      {' - '}
      {'Copyright © '}
      <Link color="inherit" href="https://www.linietsky.com/">
        Luis Linietsky - IT Solutions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
