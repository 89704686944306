import * as React from 'react';
import {  Button, Grid, Input } from '@mui/material';
import Panel from '../Panel';
import TextoLegal from '../TerminosCondicionesPoliticas/TextoLegal';
import { H2 } from '../HTML/Heading';
export default function TerminosCondicionesPage(props){

    return <>
            <Grid item xs={12}>
                <Panel>
                    <TextoLegal />
                </Panel>
            </Grid>

    </>
}