import React from 'react';
import store from './redux/Store'
import { Provider } from 'react-redux'

export default function ReduxStoreProvider({ children }){
    return (
        <Provider store={store}>
          { children }
        </Provider>
    );
}