import * as React from 'react';
import { styled } from '@mui/styles';
import Button from '@mui/material/Button';

const Input = styled('input')({
    display: 'none',
  });

export default function FileInput(props){
    var {
        id,
        variant,
        color,
        size,
        children,
        icon,
        onChange
    }=props;
    const [path, setPath] = React.useState("");
    const valueChanged = (event) => {
        if(event.target.value !== ""){
            onChange(event);
            setPath("");
        }
    }; 
    return (<>
        <label htmlFor={id}>
            <Input accept=".json" id={id} type="file" onChange={valueChanged} value={path} multiple/>
            <Button variant={variant} color={color} size={size} component="span">
                {icon}
                {icon && <>&nbsp;</>}
                {children || "Upload"}
            </Button>
        </label>
    </>)

}