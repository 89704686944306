import * as React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export function FindBoatClassInput(props){
    var {
        onBoatFound,
        label = "Tipo de barco",
        id = "find-boat-class",
        width = null,
        value=null,
    } = props;
    var dimensionesEstandar = useSelector( store => store.dimensionesEstandar);
    // var tiposBarco = dimensionesEstandar.map(tipo => tipo.Tipo.trim());
    var options = [];
    dimensionesEstandar
        .filter(tipo => tipo !== null && typeof(tipo.Tipo) === "string" && tipo.Tipo.length > 0 )
        .map(tipo => tipo.Tipo.trim())
        .forEach(tipo => {
            if(options.indexOf(tipo) === -1){
                options.push(tipo);
            }
        });
    options.sort();

    // var [options, setOptions] = React.useState([]);
    // var [value, setValue] = React.useState(null);
    // const [inputValue, setInputValue] = React.useState('');

    // const filterOptions = (event, newInputValue) => {
    //     setInputValue(newInputValue);
    //     var newOptions = tiposBarco.filter(t => t.toLowerCase().indexOf(newInputValue.toLowerCase()) !== -1  );
    //     setOptions(newOptions.slice(0,20));
    // }

    const onBoatFoundHandler = (event, newValue) => {
        console.log("Found new boat:", newValue);
        // if(!newValue)   return;
        if(typeof(onBoatFound) === 'function')
            onBoatFound({target:{value:newValue}})
        // setValue(newValue)
        // // setValue(null)
        // // setInputValue("");
        // document.getElementById(id).blur();
    }
    return (
        <Autocomplete
            id={id}
            options={options}
            autoHighlight
            onChange={onBoatFoundHandler}
            // onInputChange={filterOptions}
            size="small"
            value={value}
            width={width}
            // inputValue={inputValue}
            // filterOptions={(x) => x}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                {option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText="Escriba el tipo de barco"
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                    }}
                />
            )}
        />
      );
}