import * as React from 'react';
import './App.css';
import MainLayout from './components/MainLayout';
import ReduxStoreProvider from './providers/ReduxStoreProvider';
import InitialDataLoader from './providers/InitialDataLoader';
import { HashRouter } from 'react-router-dom';
import { AppContext } from './providers/AppContext';

function App() {

    const [user, setUser] = React.useState(false);
    const [tycAccepted, setTyCAccepted] = React.useState(false);
    const logout = () =>{
      setUser(false);
      setTyCAccepted(false);
    };
    var context = {
      user, setUser,
      tycAccepted, setTyCAccepted,
      logout
    };
    return (
        <div className="App">
          <AppContext.Provider value={context}>
            <ReduxStoreProvider>
              <HashRouter>
                <InitialDataLoader />
                <MainLayout />
              </HashRouter>
            </ReduxStoreProvider>
          </AppContext.Provider>
      </div>
    )
}

export default App;
