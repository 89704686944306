import * as React from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { Button} from '@mui/material';
function naiveRound(num, decimalPlaces = 0) {
    var p = Math.pow(10, decimalPlaces);
    return Math.round(num * p) / p;
  }
  

export const GetDetalleRatingHTML = ({rating}) => {

    var mediciones = rating["Medidas Ingresadas"]
    var SHW = Math.max( mediciones.SHW || 0, mediciones.SHWA)
    var ratings = rating["Factores"]["1 - rating"];
    var factoresFilanles = rating["Factores"]["Factores Finales"];
    var esloras = rating["Factores"]["2 - eslora"];
    var estandar = rating["Medidas Estandar"];
    var velas = rating["Factores"]["3a - velas"];
    var calado = rating["Factores"]["3b - calado"];
    var helices = rating["Factores"]["4 - helice"];
    var desplazamiento = rating["Factores"]["5 - desplazamiento"];
    var enrolladores = rating["Factores"]["7 - enrollador"];

    const tiposHelice = {
            "F2P":"Fija de 2 palas",
            "F3P":"Fija de 3 o mas palas",
            "ORI":"Orientable",
            "PLE":"Plegable",
            "S/H":"Sin hélice",
    }

    const valoresHelice = {
        "F2P":0.053,
        "F3P":0.068,
        "ORI":0.024,
        "PLE":0.019,
        "S/H":0.0,
    }

    const tiposQuilla = {
        "FIJA":"Quilla fija",
        "SQLL":"Semiquilla",
        "ORZA":"Orza o quilla movil",
        "ALAR":"Quillote alar",
    }

    const valoresTiposMotor = {
        "externo": 6,
        "interno": 9,
        "sinmotor": 0,
    }

    const tiposMotor = {
        "externo": "Externo (fuera de borda)",
        "interno": "Interno",
        "sinmotor": "Sin motor",
    }


    const printRatingDetails = () => {
        var divContents = document.getElementById("rating-details").innerHTML;

            var printablePage = `
            <html>
                <head>
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
                    <style>
                        body{
                            font-family: 'Roboto', sans-serif;
                            font-weight:400;
                            line-height:24px;
                        }
                        body b{
                            font-weight:700;
                        }
                        </style>
                </head>
                <body>
                    ${divContents}
                </body>
            </html>`;

            var a = window.open('', '', 'height=800, width=600');
            a.document.write(printablePage);
            a.document.close();
            a.print();
    }

    return (<>
    <div style={{float:"right"}}><Button onClick={printRatingDetails}><PrintIcon />&nbsp;Imprimir</Button></div>

<div id="rating-details">
<h4>1) RATING</h4>

<b>R</b>=RE x FMod x Fi; rating<br/>
{ratings.Rating} = {ratings.RE} x {ratings.FMod} x {ratings.Fi}<br/>
<br/>
<b>Rss</b>=RE x FModss x Fi x 0.965; rating sin spinnaker<br/>
{ratings.Rating} = {ratings.RE} x {ratings.FModss} x {ratings.Fi} x 0.965<br/>
<br/>
con <b>Fmod</b>=1 + FL + FV + FC + FH + FD + FE + FX; factor de modificaciones;<br/>
{ratings.FMod} = 1 + {factoresFilanles.FLE} + {factoresFilanles.FV} + {factoresFilanles.FC} + {factoresFilanles.FH} + {factoresFilanles.FD} + {factoresFilanles.FE} + {factoresFilanles.Fx}<br/>
<br/>
<b>FModss</b>=1 + FL + FVss + FC + FH + FD + FE + FX; factor de modificaciones sin spinnaker;<br/>
{ratings.FModss} = 1 + {factoresFilanles.FLE} + {factoresFilanles.FVss} + {factoresFilanles.FC} + {factoresFilanles.FH} + {factoresFilanles.FD} + {factoresFilanles.FE} + {factoresFilanles.Fx}<br/>
<br/>
y <b>Fi</b>=1 / 1.1 para todos los barcos de categoría DJM; factor de incertidumbre<br/>
{ratings.Fi} = 1 / 1.1<br/>
<br/>

    <h4>2) FL o Factor de eslora</h4>


Si ABS((LOA - LOAS) / LOA) &lt; 0.02 entonces LOAC=LOAS; en todo otro caso LOAC=LOA<br/>
<b>LOAS</b>={esloras.LOAS}<br/>
<b>LOA</b>={esloras.LOA}<br/>
ABS((LOA - LOAS) / LOA)={Math.abs( (esloras.LOA - esloras.LOAS)/esloras.LOA )}<br/>
Es {naiveRound(Math.abs( (esloras.LOA - esloras.LOAS)/esloras.LOA ),3)} &lt; 0.02 ? <b>{ Math.abs( (esloras.LOA - esloras.LOAS)/esloras.LOA ) < 0.02 ? "SI":"NO" }</b><br/>
{ (Math.abs( (esloras.LOA - esloras.LOAS)/esloras.LOA ) < 0.02) &&  (<>
    <b>LOAC</b>=LOAS={esloras.LOAS}<br/>
</>)}
{ (Math.abs( (esloras.LOA - esloras.LOAS)/esloras.LOA ) >= 0.02) &&  (<>
    <b>LOA</b>=LOA={esloras.LOA}<br/>
</>)}
<br/>
<b>FL</b>=1.13 x KL x (LOAC - LOAS) / RE ; donde <br/>
{esloras.FLE}=1.13 x {esloras.KL} x ({esloras.LOAC} - {esloras.LOAS}) / {ratings.RE} ; donde <br/>
<b>KL</b> = 1 para popas anchas;<br/>
<b>KL</b> = .5 para popas medias ;<br/> 
<b>KL</b> = 0 para popas angostas<br/>
Si LOA &lt; LOAS se tomará solamente el 50% del valor de KL.-<br/>
<b>KLS</b> = {estandar.KL}<br/>
{esloras.LOA} &lt; {esloras.LOAS} ? {esloras.LOA < esloras.LOAS ? "SI" : "NO"}<br/>
<b>KL</b>={esloras.KL}<br/>

    <h4>3) FV o Factor de vela</h4>


<ul>
<li className={mediciones.HLU >= (0.9*estandar.HLUS) ? "bold" : null}>Si HLU &ge; 0.9 x HLUS entonces ICALC = SQR((1.04 x HLU)<sup>2</sup> - J<sup>2</sup>)</li>
<li className={(mediciones.HLU < (0.9*estandar.HLUS) && mediciones.HLU > (0.85*estandar.HLUS)) ? "bold" : null}>Si HLU està entre 0.85 x HLUS y 0.9 x HLUS entonces ICALC = SQR((1.04 x 0.9 x HLUS)<sup>2</sup> - J<sup>2</sup>)</li>
<li className={mediciones.HLU <= (0.85*estandar.HLUS) ? "bold" : null}>Si HLU &le; 0.85 x HLUS entonces ICALC = SQR((1.04 x HLUS)<sup>2</sup> - J<sup>2</sup>)</li>
</ul>
{mediciones.HLU >= (0.9*estandar.HLUS) && <div><b>ICALC</b>=SQR((1.04 x {mediciones.HLU})<sup>2</sup> - {mediciones.J}<sup>2</sup>)</div>}
{(mediciones.HLU < (0.9*estandar.HLUS) && mediciones.HLU > (0.85*estandar.HLUS)) && <div><b>ICALC</b>=SQR((1.04 x 0.9 x {estandar.HLUS})<sup>2</sup> - {mediciones.J}<sup>2</sup>)</div>}
{mediciones.HLU <= (0.85*estandar.HLUS) && <div><b>ICALC</b>=SQR((1.04 x {estandar.HLUS})<sup>2</sup> - {mediciones.J}<sup>2</sup>)</div>}
{velas.ICALC}
<br/>
{/* <b>ICALC</b>=SQR(FL<sup>2</sup> - J<sup>2</sup>)<br/>
{velas.ICALC}=SQR({mediciones.FL}<sup>2</sup> - {mediciones.J}<sup>2</sup>)<br/> */}



<br/>

<b>ICALCS</b>=SQR((1.04 * FLS)<sup>2</sup> - JS<sup>2</sup>)<br/>
{velas.ICALCS}=SQR({estandar.FLS}<sup>2</sup> - {estandar.JS}<sup>2</sup>)<br/>
{/* <b>ICALCS</b>=SQR(FLS<sup>2</sup> - JS<sup>2</sup>)<br/>
{velas.ICALCS}=SQR({estandar.FLS}<sup>2</sup> - {estandar.JS}<sup>2</sup>)<br/> */}
<br/>

<b>HPS</b>=STLS + 2 x BPROAS + 2 x LPS + 1.703 x SHWS ;<br/>
{velas.HPS}=STLS + 2 x {estandar.BPROAS} + 2 x {estandar.LPS} + 1.703 x {estandar.SHWS} ;<br/>
<br/>

<b>VPS</b>=ICALCS + SLS<br/>
{velas.VPS}={velas.ICALCS} + {estandar.SLS}<br/>
<br/>

<b>SPS</b>=0.0457 x HPS x VPS - 0.0031 x HPS<sup>2</sup><br/>
{velas.SPS}=0.0457 x {velas.HPS} x {velas.VPS} - 0.0031 x {velas.HPS}<sup>2</sup><br/>
<br/>

<b>SMS</b> = 0.7 x (0.6404 x PS x ES)<br/>
{velas.SMS} = 0.7 x (0.6404 x {estandar.PS} x {estandar.ES})<br/>
<br/>

<b>SYS</b> = (0.1275 x PYS ^ 2 x EYS ^ 2) / (SMS + 0.5 x PYS x EYS)<br/>
{velas.SYS} = (0.1275 x {estandar.PYS}<sup>2</sup> x {estandar.EYS} <sup>2</sup>) / ({velas.SMS} + 0.5 x {estandar.PYS} x {estandar.EYS})<br/>
<br/>

<b>SVS</b>=SQR(SPS + SMS + SYS)<br/>
{velas.SVS}=SQR({velas.SPS} + {velas.SMS} + {velas.SYS})<br/>
<br/>

STLC será el mayor de STL o J; <br/>
<b>STL</b>={velas.STL}<br/>
<b>J</b>={velas.J} <br/>
<b>STLC</b>={velas.STL > velas.J ? "STL" : "J"}={Math.max(velas.STL, velas.J)}<br/>
<br/>
LPC será el mayor de LP o J; <br/>
<b>LP</b>={velas.LP}<br/>
<b>J</b>={velas.J} <br/>
<b>LPC</b>={velas.LP > velas.J ? "LP" : "J"}={Math.max(velas.LP, velas.J)}<br/>
<br/>
SHWC será el mayor de SHW o 1.5 x J;<br/>
<b>SHW</b>={SHW}<br/>
<b>J x 1.5</b>={velas.J * 1.5} <br/>
<b>SHWC</b>={SHW > (velas.J*1.5) ? "SHW" : "Jx1.5"}={Math.max(SHW, velas.J * 1.5)}<br/>

<br/>
SLC será el mayor de SL o .9 x ICALC.<br/>
<b>SL</b>={mediciones.SL}<br/>
<b>0.9 x ICALC</b>={velas.ICALC * 0.9} <br/>
<b>SLC</b>={mediciones.SL > (velas.ICALC*0.9) ? "SL" : "0.9 x ICALC"}={Math.max(mediciones.SL, velas.ICALC*0.9)}<br/>
<br/>

<b>HP</b>=STLC + 2 x J + 2 x LPC+1.703 x SHWC ; <br/>
{velas.HP}={velas.STLC} + 2 x {mediciones.J} + 2 x {velas.LPC}+1.703 x {velas.SHWC} ; <br/>
<br/>

<b>VP</b>=ICALC + SLC ; <br/>
{velas.VP}={velas.ICALC} + {velas.SLC} ; <br/>
<br/>

<b>SP</b>=0.0457 x HP x VP - 0.0031 x HP<sup>2</sup><br/>
{velas.SP}=0.0457 x {velas.HP} x {velas.VP} - 0.0031 x {velas.HP}<sup>2</sup><br/>
<br/>


<b>HPss</b>=STLC + 2 x J + 2 x LPC + 1.7 x 1.8 x J ; <br/>
HPss={velas.STLC} + 2 x {mediciones.J} + 2 x {velas.LPC} + 1.7 x 1.8 x {mediciones.J} ; <br/>
<br/>

<b>VPss</b>=2 x ICALC ; <br/>
{velas.VPss}=2 x {velas.ICALC} ; <br/>
<br/>

<b>SPss</b>=0.0457 x HPss x VPss - 0.0031 x HPss<sup>2</sup><br/>
{velas.SPss}=0.0457 x {velas.HPss} x {velas.VPss} - 0.0031 x {velas.HPss}<sup>2</sup><br/>
<br/>

<b>SM</b> = (0.1684 * E + 0.2582 * MHW + 0.2341 * MTW + 0.105 * MUW) * P<br/>
{velas.SM} = (0.1684 * {mediciones.E} + 0.2582 * {mediciones.MHW} + 0.2341 * {mediciones.MTW} + 0.105 * {mediciones.MUW}) * P<br/>
<br/>

<b>SY</b> = (0.1275 x EY ^ 2 x PY ^ 2) / (SM + 0.5 x PY x EY)<br/>
{velas.SY} = (0.1275 x {mediciones.EY} ^ 2 x {mediciones.PY} ^ 2) / ({velas.SM} + 0.5 x {mediciones.PY} x {mediciones.EY})<br/>
<br/>

<b>SV</b>=SQR(SP + SM + SY)<br/>
{velas.SV}=SQR({velas.SP} + {velas.SM} + {velas.SY})<br/>
<br/>

<b>SVss</b>=SQR(SPss + SM + SY)<br/>
{velas.SVss}=SQR({velas.SPss} + {velas.SM} + {velas.SY})<br/>
<br/>

<b>FV</b>=2.4 x (SV - SVS) / RE<br/>
{factoresFilanles.FV}=2.4 x ({velas.SV} - {velas.SVS}) / {ratings.RE}<br/>
<br/>

<b>FVss</b>=2.4 x (SVss - SVS) / RE<br/>
{factoresFilanles.FVss}=2.4 x ({velas.SVss} - {velas.SVS}) / RE<br/>
<br/>


<h4>4) FC o Factor de calado</h4>

<b>CD</b>=CMAX-CMIN<br/>
{calado.CD}={mediciones.CMAX}-{mediciones.CMIN}<br/>
<br/>
<b>CDS</b>=CMAXS-CMINS<br/>
{calado.CDS}={estandar.CMAXS}-{estandar.CMINS}<br/>
<br/>
a. Para quillas fijas : RD = CMIN y RDS = CMINS<br/>
b. Para semiquillas: RD = CMIN + .5 x CD y RDS = CMINS + .5 x CDS<br/>
c. Para orzas o quillas móviles: RD = CMIN + CD y RDS = CMINS + CDS<br/>
d. Para quillotes alares: RD = CMIN + .3 x WD y RDS = CMINS + .3 x WDS<br/>
<br/>
Tipo: <b>{tiposQuilla[mediciones.tipoQuilla]}</b><br/>
{mediciones.tipoQuilla === "FIJA" && (<>
    <b>RD</b> = CMIN = {mediciones.CMIN}<br/>
    <b>RDS</b> = CMINS = {estandar.CMINS}<br/>
</>)}
{mediciones.tipoQuilla === "SQLL" && (<>
    <b>RD</b> = CMIN + .5 x CD<br/>
    {calado.RD} = {mediciones.CMIN} + 0.5 x {calado.CD}<br/>
    <b>RDS</b> = CMINS + .5 x CDS<br/>
    {calado.RDS} = {estandar.CMINS} + 0.5 x {calado.CDS}<br/>
</>)}
{mediciones.tipoQuilla === "ORZA" && (<>
    <b>RD</b> = CMIN + CD<br/>
    {calado.RD} = {mediciones.CMIN} + {calado.CD}<br/>
    <b>RDS</b> = CMINS + CDS<br/>
    {calado.RDS} = {estandar.CMINS} + {calado.CDS}<br/>
</>)}
{mediciones.tipoQuilla === "ALAR" && (<>
    <b>RD</b> = CMIN + .3 x WD<br/>
    {calado.RD} = {mediciones.CMIN} + 0.3 x {mediciones.WD}    <br/>
    <b>RDS</b> = CMINS + .3 x WDS<br/>
    {calado.RDS} = {estandar.CMINS} + 0.3 x {estandar.WDS}    <br/>
</>)}
<br/>
<b>DB</b>=3.02 x SQR(SQR(LOAS)) - 3.58<br/>
{calado.DB}=3.02 x SQR(SQR({estandar.LOAS})) - 3.58<br/>
<br/>

<b>FC</b>=0.16 x LOA x (RD<sup>2</sup> - RDS<sup>2</sup>) / DB<sup>2</sup> / RE<br/>
{factoresFilanles.FC}=0.16 x {mediciones.LOA} x ({calado.RD}<sup>2</sup> - {calado.RDS}<sup>2</sup>) / {calado.DB}<sup>2</sup> / {ratings.RE}<br/>

<br/>

<h4>5) FH o Factor de hélice</h4>
Para hélices fijas de tres o más palas PF = .068<br/>
Para hélices fijas de dos palas PF = .053<br/>
Para hélices orientables PF = .024<br/>
Para hélices plegables PF = .019<br/>
Para barcos sin hélice PF = 0<br/>

Tipo de helice: <b>{tiposHelice[mediciones.tipoHelice]}</b><br/>
<b>PF</b> = {valoresHelice[mediciones.tipoHelice]}<br/><br/>

La hélice deberá ser de tales dimensiones que pueda propulsar al barco a motor a 1.75 x SQR(LOA)<br/>
nudos en agua quieta. Es responsabilidad del propietario comprobar esto. De lo contrario PF = 0.<br/>

FH=(PFS - PF) / (1 - PFS)<br/>
{factoresFilanles.FH}=({helices.PFS} - {helices.PF}) / (1 - {helices.PFS})<br/>

<h4>6) FD o Factor de desplazamiento</h4>
El factor de desplazamiento es solo aplicable a casos de barcos que se diferencien de su tipo por<br/>
carencias de interior, grandes modificaciones de lastre, quillote o bien construcciones atípicas. No<br/>
se pretende distinguir entre sutiles diferencias de desplazamiento sino compensar por grandes<br/>
desviaciones del standard. La magnitud del valor DK quedará a criterio de la CIC.<br/><br/>


<b>FD</b> = -0.004 x (DK + EW) / LOAS<sup>2</sup><br/>
{factoresFilanles.FD} = -0.004 x ({desplazamiento.DK} + {desplazamiento.EW}) / {estandar.LOAS}<sup>2</sup><br/>
<br/>

Donde:<br/>
Para motores fuera de borda que se llevan a bordo en regata: EW=6xLOAS<br/>
Para motores internos: EW=9 x LOAS<br/>

Tipo de motor: <b>{tiposMotor[mediciones.tipoMotor]}</b>. Coeficiente EW: {valoresTiposMotor[mediciones.tipoMotor]}<br/>
<b>EW</b> = {valoresTiposMotor[mediciones.tipoMotor]} x LOAS<br/>
{desplazamiento.EW} = {valoresTiposMotor[mediciones.tipoMotor]} x {estandar.LOAS}<br/>
<br/>

<h4>7) FX o Factor de extras</h4>
La aplicación del factor de extras quedará librado al criterio de la CIC y se utilizará para contemplar<br/>
otros elementos que pudieran afectar la performance como distribución de pesos, apéndices<br/>
móviles, mástiles excesivamente esbeltos, etc. También será de aplicación, cuando el FMod<br/>
resultante del cálculo no reflejara la verdadera performance diferencial que un barco lograra en<br/>
virtud de sus modificaciones.<br/>
Para barcos que utilizaran un tangón además de un botalón estándar como arbotantes para braza,<br/>
Fx=0.01, más lo que pudiera corresponder por los motivos antes citados.<br/>
<br/>

{ mediciones.usaTangon && mediciones.usaBotalon && (<>
    Utiliza tangon y botalon.<br/>
    <b>Fx</b> = 0.01
</>)}
{ mediciones.usaTangon && !mediciones.usaBotalon && (<>
    Utiliza solo tangon.<br/>
    <b>Fx</b> = 0
</>)}

{ !mediciones.usaTangon && mediciones.usaBotalon && (<>
    Utiliza solo botalon.<br/>
    <b>Fx</b> = 0
</>)}



<h4>8) Factor de enrolladores</h4>
Si el barco<br/>
a) tiene enrollador de genoa y<br/>
b) solamente usa en regata la genoa enrollable y ninguna otra vela de proa con excepción de:<br/>
1. un tormentín o<br/>
2. un foque de tormenta que cumpla con LL x LP x 0.5 &le; 0.135 x (FL^2 – J^2) siendo LL su<br/>
largo de gratil.<br/>
c) la genoa enrollable tiene un LP&gt;1.3xJ<br/>
entonces, FEG = 0.0099. Sino FEG = 0.<br/><br/>


<b>{mediciones.velaDeProaDeEnrollar ? "Usa vela de proa de enrollar" : "No usa vela de proa de enrollar"}</b><br/>
{mediciones.velaDeProaDeEnrollar && (<>
    LP={mediciones.LP}<br/>
    J={mediciones.J}<br/>
    Es LP &gt; 1.3 x J ? <b>{ mediciones.LP > (1.3*mediciones.J) ? "SI" : "NO" }</b><br/>
</>)}
<b>FEG</b> = { mediciones.LP > (1.3*mediciones.J) ? 0.0099 : 0 } <br/>
<br/>

Si el barco tiene enrollador de mayor en el mástil entonces FEM=0.0097. Sino FEM=0.<br/>
<br/>

<b>{mediciones.mayorDeEnrollar ? "Usa mayor de enrollar" : "No usa mayor de enrollar"}</b><br/>
<b>FEM</b> = {mediciones.mayorDeEnrollar ? 0.0097 : 0}<br/>
<br/>

<b>FE</b> = FEG + FEM<br/>
{factoresFilanles.FE} = {enrolladores.FEG} + {enrolladores.FEM}<br/>

</div>
</>)


}