import * as React from 'react';

import { Button, Grid, MenuItem } from '@mui/material';
import Panel from '../Panel';
import NumberInput from '../FormElements/NumberInput';
import FileInput from '../FormElements/FileInput';
import AlertDialog from '../Dialogs/AlertDialog';
import TextField from '@mui/material/TextField';
import { H2, H3 } from '../HTML/Heading';
import SelectInput from '../FormElements/SelectInput';
import { useSelector } from 'react-redux';
import { calcRating, getDEForBoatClass } from '../../utils/calculaRating';
import { DetallesDeRartingVertical } from '../DetallesDeRartingVertical';
import AddchartIcon from '@mui/icons-material/Addchart';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { GridOneQuarter } from '../Grid/GridOneQuarter';
// import { GridTwoQuarters } from '../Grid/GridTwoQuarters';
import { FindBoatClassInput } from '../FormElements/FindBoatClassInput';
import saveJsonAsFile from '../../utils/file_management';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { GetDetalleRatingHTML } from '../../utils/imprime_rating';
import TextoLegal from '../TerminosCondicionesPoliticas/TextoLegal';

function validateParams(params){
    if(typeof(params.boatClassName) === "undefined" || params.boatClassName === null) return false;
    return Object.keys(PARAMETROS_INICIALES).reduce( (previous, key) => previous && (key in params), true);

}

const PARAMETROS_INICIALES = {
    boatClassName:"",
    LOA:0.0,
    // FL:0.0,
    HLU:0.0,
    LP:0.0,
    J:0.0,
    E:0.0,
    P:0.0,
    STL:0.0,
    SHW:0.0,
    SL:0.0,
    SHWA:0.0,
    SLU :0.0,
    SLE :0.0,
    MHW:0.0,
    MTW:0.0,
    MUW:0.0,
    MGY:0.0,
    EY:0.0,
    PY:0.0,
    COEFICIENTE_EW:0.0,
    PF:0.0,
    usaTangon:false,
    usaBotalon:false,
    velaDeProaDeEnrollar:false,
    mayorDeEnrollar:false,
    CMAX:0.0,
    CMIN:0.0,
    tipoQuilla:"",
    tipoMotor:"",
    tipoHelice:"",
    WD:0.0
  };


export default function RaitingComponent(props){
    const [params, setParams] = React.useState(/*PUROROCK ||*/ PARAMETROS_INICIALES);
    var dimensionesEstandar = useSelector( store => store.dimensionesEstandar);
    var [openSaveFileDialog, setOpenSaveFileDialog] = React.useState(false);
    var [showErrorLoadingFile, setShowErrorLoadingFile] = React.useState(false);
    var [mostrarDetalle, setMostrarDetalle ] = React.useState(false);
    var ratings = useSelector( store => store.ratings);
    var tiposBarco = dimensionesEstandar.map(tipo => tipo.Tipo.trim());
    tiposBarco.sort()
    const boatFileLoaderId = "filename-loader-boat";
    console.log("Params:", params)
    const completarConDE = () => {
        var de = getDEForBoatClass(params.boatClassName, dimensionesEstandar);
        console.log("DE:",de);
        var newParams = {...PARAMETROS_INICIALES, 
            boatClassName: params.boatClassName,
            J: parseFloat(de.BProa),
            CMAX: parseFloat(de.CMIN) + parseFloat(de["CMAX-CMIN"]),
            CMIN: parseFloat(de.CMIN),
            LOA:parseFloat(de.LOA),
            E:parseFloat(de.E),
            MUW:parseFloat((parseFloat(de.E)*0.22).toFixed(2)),
            MTW:parseFloat((parseFloat(de.E)*0.38).toFixed(2)),
            MHW:parseFloat((parseFloat(de.E)*0.66).toFixed(2)),
            EY:parseFloat(de.EY),
            // FL:parseFloat(de.FL),
            HLU:parseFloat(de.HLU),
            LP:parseFloat(de.LPG),
            P:parseFloat(de.P),
            PY:parseFloat(de.PY),
            SHW:parseFloat(de.SHW),
            SL:parseFloat(de.SL),
            STL:parseFloat(de.STL),
            WD:parseFloat(de.WD),
            tipoQuilla: de.Quilla,
            tipoHelice: de.Helice,
            usaTangon:true,
            usaBotalon:false,
            tipoMotor:"sinmotor"
        };
        setParams(newParams);
    }



    // console.log("params", params)
    const getOnChange = (prop) => (event) => {
        var newParams = {...params};
        newParams[prop] = event.target.value;
        if(typeof(newParams[prop]) === "string" && !isNaN(newParams[prop])) newParams[prop] = parseFloat(newParams[prop])
        setParams(newParams)
    }

      const onChangeUsaTangon = (event) => {
        var newParams = {...params, 
            usaTangon: event.target.value === "SI"  || event.target.value === "AMBOS", 
            usaBotalon: event.target.value === "NO"  || event.target.value === "AMBOS", 
        };
        setParams(newParams)
      }
      const getUsaTangonValue = ()=>{
        if(params.usaTangon && params.usaBotalon){
            return "AMBOS"
        }else if (params.usaTangon ){
            return "SI"
        }else if (params.usaBotalon ){
            return "NO"
        }else{
            return "";
        }
      }
      
    const rating = validateParams(params) && calcRating(params, ratings, dimensionesEstandar) ;
      console.log("rating", rating);

    const handleSaveFileDialog = (name) => {
        setOpenSaveFileDialog(false);
        if(name.trim().length > 0)
        {
            name = name.replace(/[^a-z0-9]/gmi, " ").replace(/\s+/g, "_");
            saveJsonAsFile(name+".json", params);
        }
    };

    const completarBarcoGuardado = (event) => {
        if(event.target.value.trim() === "") return;
        var files = document.getElementById(boatFileLoaderId).files;
        if(files.length === 0) return;
        var reader = new window.FileReader();
        reader.readAsText(files[0], "UTF-8");
        reader.onload = (e) => {
            try{
                var newParams = JSON.parse(e.target.result);
                var isValidFile = validateParams(newParams);
                console.log("Is Valid?", isValidFile);
                if(!isValidFile){ 
                    throw new Error("Archivo invalido");
                }
                setParams(newParams);
            }catch(e){
                setShowErrorLoadingFile(true);
            }
        }

    };



    return <>

        {/* <div style={{display:"block", width:"100%",backgroundColor:"red",height:"100"}}>&nbsp;</div> */}
      <SaveFileDialog 
        open={openSaveFileDialog}
        onClose={handleSaveFileDialog}
      />
      <AlertDialog 
          open={showErrorLoadingFile}
          onClose={() => {console.log(11111);setShowErrorLoadingFile(false);}}
          title="Error abriendo archivo"
          description="El archivo que intento abrir no es valido. Intente abrir un archivo que haya descargado con este mismo aplicativo."
          accept="Continuar"
      />
      <Grid container  spacing={3}>

        <Grid item xs={12}>
            <Panel>
                <H2>Calculadora de ratings</H2>
                <p>Para crear una nueva medición, indique un tipo de embarcación para iniciar el proceso. A continuación podrá completar el formulario a mano o hacer click en "Completar" para ya cargarlo con las dimensiones estandar de dicha embarcación. Despues podra guarar las medidas de su barco en un archivo para a futuro poder continuar trabajando en el.</p>
                <p>Para continuar trabajando en un barco guardado con anterioridad, haga click en "Cargar barco" y busque el archivo en el que guardo sus cambios.</p>
            </Panel>
        </Grid>

        <Grid item lg={9} xs={12}>
            <Grid container spacing={3}>



                <Grid item xs={12}>
                    <Panel>
                        <H3>Indique el tipo de barco</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                {/* <SelectInput id="tipoBarco" label="Tipo de Barco" variant="outlined" value={params.boatClassName} onChange={getOnChange("boatClassName")}>
                                    <MenuItem value="">Seleccione una opción</MenuItem>
                                    {tiposBarco.map( (tipo,i) => (<MenuItem value={tipo} key={i}>{tipo}</MenuItem>))}
                                </SelectInput> */}
                                <FindBoatClassInput
                                    // value={params.boatClassName}
                                    onBoatFound={getOnChange("boatClassName")}
                                    value={params.boatClassName || ""}
                                     />
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <Button variant="contained" size="small" onClick={completarConDE} title="Completar los siguientes campos con las dimensiones estandar"><AddchartIcon />&nbsp;Completar </Button>
                                {/* <Button onClick={() => setParams(MEDIDAS.PURO_ROCK)}>Puro Rock</Button> */}
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <FileInput id={boatFileLoaderId} color="secondary" variant="contained" size="small" icon={<FileUploadIcon/> } onChange={completarBarcoGuardado}>Cargar barco</FileInput>
                            </GridOneQuarter>
                            <GridOneQuarter>
                            { params.boatClassName !== "" && (<>
                                <Button color="primary" variant="contained" size="small" onClick={() => setOpenSaveFileDialog(true)} title="Guardar el barco guardado"><SaveIcon />&nbsp;Guardar</Button>
                                </>) }
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                {/* <Grid item xs={6}>
                    <Panel>
                        <H3>Indique el tipo de barco</H3> <br/>
                        <Grid container>
                            <GridTwoQuarters>
                                <FileInput id={boatFileLoaderId} color="secondary" variant="contained" size="small" icon={<FileUploadIcon/> } onChange={completarBarcoGuardado}>Cargar barco</FileInput>
                            </GridTwoQuarters>
                        </Grid>
                    </Panel>
                </Grid> */}

            { params.boatClassName !== "" && (<> 
            
                <Grid item xs={12}>
                    <Panel>
                        <H3>Casco y Quillote</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <NumberInput id="loa" label="LOA" variant="outlined" value={params.LOA} onChange={getOnChange("LOA")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="cmin" label="CMIN" variant="outlined" value={params.CMIN} onChange={getOnChange("CMIN")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="cmax" label="CMAX" variant="outlined" value={params.CMAX} onChange={getOnChange("CMAX")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="wd" label="WD" variant="outlined" value={params.WD} onChange={getOnChange("WD")}/>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Spinnaker Simetrico</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <NumberInput id="shw" label="SHW" variant="outlined" value={params.SHW} onChange={getOnChange("SHW")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="sl" label="SL" variant="outlined" value={params.SL} onChange={getOnChange("SL")}/>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Spinnaker Asimetrico</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <NumberInput id="shwa" label="SHW" variant="outlined"  value={params.SHWA} onChange={getOnChange("SHWA")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="slu" label="SLU" variant="outlined"  value={params.SLU} onChange={getOnChange("SLU")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="sle" label="SLE" variant="outlined" value={params.SLE} onChange={getOnChange("SLE")}/>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Triangulo de Proa</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <NumberInput id="j" label="J" variant="outlined" value={params.J} onChange={getOnChange("J")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                            <NumberInput id="hlu" label="HLU" variant="outlined" value={params.HLU} onChange={getOnChange("HLU")}/>
                                {/* <NumberInput id="fl" label="FL" variant="outlined" value={params.FL} onChange={getOnChange("FL")}/> */}
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="lp" label="LP" variant="outlined" value={params.LP} onChange={getOnChange("LP")}/>
                            </GridOneQuarter>
                            
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Tangon / Botalon</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <NumberInput id="stl" label="STL" variant="outlined"  value={params.STL} onChange={getOnChange("STL")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <SelectInput id="usa_tangon" label="Arraigo de Spinnaker" variant="outlined" value={getUsaTangonValue()} onChange={onChangeUsaTangon}>
                                    <MenuItem value="">Seleccione una opción</MenuItem>
                                    <MenuItem value="SI">Uso Tangon</MenuItem>
                                    <MenuItem value="NO">Uso Botalon</MenuItem>
                                    <MenuItem value="AMBOS">Uso Ambos</MenuItem>
                                </SelectInput>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Mayor</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <NumberInput id="p" label="P" variant="outlined"  value={params.P} onChange={getOnChange("P")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="e" label="E" variant="outlined"  value={params.E} onChange={getOnChange("E")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="muw" label="MUW" variant="outlined" value={params.MUW} onChange={getOnChange("MUW")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="mtw" label="MTW" variant="outlined" value={params.MTW} onChange={getOnChange("MTW")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="mhw" label="MHW" variant="outlined" value={params.MHW} onChange={getOnChange("MHW")}/>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Mesana</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <NumberInput id="py" label="PY" variant="outlined"  value={params.PY} onChange={getOnChange("PY")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="ey" label="EY" variant="outlined" value={params.EY} onChange={getOnChange("EY")}/>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="mgy" label="MGY" variant="outlined"  value={params.MGY} onChange={getOnChange("MGY")}/>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Plano Lateral</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <SelectInput id="tipoPlanoLateral" label="Tipo de Plano Lateral" variant="outlined" value={params.tipoQuilla} onChange={getOnChange("tipoQuilla")}>
                                    <MenuItem value="">Seleccione una opción</MenuItem>
                                    <MenuItem value="FIJA">Quillote</MenuItem>
                                    <MenuItem value="ORZA">Orza</MenuItem>
                                    <MenuItem value="SQLL">Semiquilla</MenuItem>
                                    <MenuItem value="ALAR">Quillote alar</MenuItem>
                                    <MenuItem value="Otro">Otro</MenuItem>
                                </SelectInput>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <NumberInput id="otra_quilla" label="Otra Quilla" variant="outlined"/>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Enrolladores</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <SelectInput id="mayorDeEnrollar" label="Tiene mayor de enrollar?" variant="outlined" value={params.mayorDeEnrollar} onChange={getOnChange("mayorDeEnrollar")}>
                                    <MenuItem value="">Seleccione una opción</MenuItem>
                                    <MenuItem value={false}>Sin enrollador</MenuItem>
                                    <MenuItem value={true}>Con enrollador</MenuItem>
                                </SelectInput>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <SelectInput id="velaDeProaDeEnrollar" label="Tiene vela de proa de enrollar?" variant="outlined" value={params.velaDeProaDeEnrollar} onChange={getOnChange("velaDeProaDeEnrollar")}>
                                    <MenuItem value="">Seleccione una opción</MenuItem>
                                    <MenuItem value={false}>Sin enrollador</MenuItem>
                                    <MenuItem value={true}>Con enrollador</MenuItem>
                                </SelectInput>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Propulsion Mecanica</H3>
                        <br/>
                        <Grid container>
                            <GridOneQuarter>
                                <SelectInput id="tipoMotor" label="Tipo de motor" variant="outlined" value={params.tipoMotor} onChange={getOnChange("tipoMotor")}>
                                    <MenuItem value="">Seleccione una opción</MenuItem>
                                    <MenuItem value="sinmotor">Sin motor</MenuItem>
                                    <MenuItem value="externo">Fuera de borda</MenuItem>
                                    <MenuItem value="interno">Interno</MenuItem>
                                </SelectInput>
                            </GridOneQuarter>
                            <GridOneQuarter>
                                <SelectInput id="tipoHelice" label="Tipo de Hélice" variant="outlined" value={params.tipoHelice} onChange={getOnChange("tipoHelice")}>
                                    <MenuItem value="">Seleccione una opción</MenuItem>
                                    <MenuItem value="S/H">Sin Hélice</MenuItem>
                                    <MenuItem value="PLE">Plegable</MenuItem>
                                    <MenuItem value="ORI">Orientable</MenuItem>
                                    <MenuItem value="F2P">Fija 2 Palas</MenuItem>
                                    <MenuItem value="F3P">Fija 3 Palas</MenuItem>
                                </SelectInput>
                            </GridOneQuarter>
                        </Grid>
                    </Panel>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <H3>Detalle del calculo de ratings</H3>
                        {rating && (<>
                        {mostrarDetalle && (<>
                            <Button size="small" onClick={() => setMostrarDetalle(false)}>Ocultar detalle</Button><br/>
                            <div><GetDetalleRatingHTML rating={rating}/></div>
                        </>)}
                        {!mostrarDetalle && (<>
                            <Button size="small" onClick={() => setMostrarDetalle(true)}>Mostrar detalle</Button>
                        </>)}
                    </>) }
                        {/* {rating && (<div><GetDetalleRatingHTML rating={rating}/></div>)} */}
                        {!rating && <p>Aun no es posible calcular el rating. Complete los campos correctamente.</p>}
                    </Panel>
                </Grid>
                </>)
        }
            </Grid>

            
        </Grid>
        <Grid item lg={3} xs={12}> 
            <Panel className="left">
            <H3>Raiting</H3>
                {rating && <DetallesDeRartingVertical rating={rating}/> }
                {!rating && <p>Aun no es posible calcular el rating. Complete los campos correctamente.</p>}
            </Panel>
        </Grid>

      </Grid>


        
      
    </>
}

function SaveFileDialog({open, onClose, initialValue=""})
{
    const [filename, setFilename] = React.useState(initialValue);

    return (<>
          <Dialog
        onClose={() => onClose("")}
        open={open}
        fullWidth
        >
        <DialogTitle>Guardar medidas de embarcación</DialogTitle>
        <DialogContent>
            {/* <Typography>Ingrese el nombre de la embarcación</Typography> */}
            <br/>
            <TextField 
                id={'boat-filename-to-save'}
                label={'Nombre del archivo'}
                variant="outlined"
                value={filename}
                onChange={(event) => setFilename(event.target.value)}
                fullWidth
            />

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => onClose("")}>
            Cancelar
          </Button>
          <Button autoFocus onClick={() => onClose(filename)}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>)

}

