import * as React from 'react';
import AuthRatings from '../Rating/AuthRatings';
import RaitingComponent from '../Rating/RaitingComponent'
import AceptarTyCParaContinuar from '../TerminosCondicionesPoliticas/AceptarTyCParaContinuar';
export default function RatingCalcPage(props){
    

    return <>
        <AuthRatings>
            <AceptarTyCParaContinuar>
                <RaitingComponent />
            </AceptarTyCParaContinuar>
        </AuthRatings>
    </>
}
