import axios from 'axios';
import { setRatings, setSyncDate } from './redux/Actions';

function getRatingsCicUrl(){
    if( !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ){
        return "/data/ratings.cic";
    }else{
        return "/get_ratings.php";
    }
}

export const loadRatings = async (dispatch) => {
    var response = await axios({
        method: 'get',
        url: getRatingsCicUrl(),
    });
    var ratings = readRatings(response.data);
    ratings = removeDuplicates(ratings);
    // console.log("ratings", ratings)
    dispatch(setRatings(ratings));
    dispatch(setSyncDate(new Date()));
    console.log("Updating ratings")
}

const compareToBoat = (rating) => (r1) => r1.serie === rating.serie &&  r1.sail === rating.sail &&  r1.name === rating.name &&  r1.class === rating.class;

const removeDuplicates = ratings => {
    var response = [];
    for(var i = 0 ; i < ratings.length ; i++){
        var rating = ratings[i];
        if(!response.some(compareToBoat(rating) )){
            response.push(rating);
        }
    }
    return response;
}

const readRatings = (ratingsCic) => {
    var ratings= ratingsCic.split("\r\n").map( line => {
        return {
            serie: line.substr(0,5).trim(),
            sail: line.substr(5,7).trim(),
            name: line.substr(12,13).trim(),
            class : line.substr(25,13).trim(),
            rating: line.substr(38,7).trim(),
            ratingSs: line.substr(45,6).trim(),
            numeritoNoSeQue: line.substr(54,6).trim(),
            medido: line.substr(60,6).trim(),
        }
    })
    return ratings
}