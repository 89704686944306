import axios from 'axios';
import { setDimensionesEstandar, setSyncDate } from './redux/Actions';

function getDeCicUrl(){
    if( !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ){
        return "/data/ds.csv";
    }else{
        return "/get_dimensiones_estandar.php";
    }
}



export const loadDimensionesEstandar = async (dispatch) => {
    var response = await axios({
        method: 'get',
        url: getDeCicUrl(),
    });
    var dimensionesEstandar = parseCsv(response.data);
    dispatch(setDimensionesEstandar(dimensionesEstandar));
    dispatch(setSyncDate(new Date()));
    console.log("Updating DS")

}

const parseCsv = (content) => {
    var lines = content.split("\n");
    var headers = lines.shift().split(",")
    var rows = [];
    for (var i = 0; i < lines.length; i++) {
        var values = lines[i].split(",")
        if (values.length < headers.length || values[0].length === 0) continue;
        var elem = {};
        for (var j = 0; j < headers.length; j++) {
            elem[headers[j].replace(/"/g, "").trim()] = values[j].replace(/"/g, "").trim()
        }
        rows.push(elem)
    }
    return rows;
}