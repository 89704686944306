import { IconButton, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { FindBoatInput } from '../FormElements/FindBoatInput';
import { H2 } from '../HTML/Heading';
import Panel from '../Panel';
import { GridOneQuarter } from '../Grid/GridOneQuarter';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getTCF } from '../../utils/calculaRating';
import SelectInput from '../FormElements/SelectInput';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import { makeStyles } from '@mui/styles';
import { GridThreeQuarters } from '../Grid/GridThreeQuarters';

const sortByName = (a,b) => {
    if(a.name > b.name ) return 1;
    else if (a.name < b.name ) return -1;
    else return 0;
}

const sortByClass = (a,b) => {
    if(a.class > b.class ) return 1;
    else if (a.class < b.class ) return -1;
    else return 0;
}

const sortByRating = (a,b) => {
    if(parseFloat(a.rating) > parseFloat(b.rating) ) return -1;
    else if (parseFloat(a.rating) < parseFloat(b.rating) ) return 1;
    else return 0;
}

const sortBySerie = (a,b) => {
    if(a.serie > b.serie) return 1;
    else if(a.serie < b.serie) return -1;
    else return 0;
}

const useStyles = makeStyles((theme) => ({
    hiddenInXs: {
      [theme.breakpoints.only("xs")]: {
        display: "none",
      },
    },
    hiddenInSm: {
        [theme.breakpoints.only("xs")]: {
            display: "none",
          },
        [theme.breakpoints.only("sm")]: {
            display: "none",
          },
        },
  
  }));

export function PagoPagaPage(props){
    var [competidores, setCompetidores] = React.useState([]);
    var [userBoat, setUserBoat] = React.useState(null);
    var [esConSpinnaker, setEsConSpinnaker] = React.useState(true);

    const agregarCompetidor = (boat) => {
        var nuevosCompetidores = [...competidores, boat]
        nuevosCompetidores = nuevosCompetidores.sort(sortByName);
        nuevosCompetidores = nuevosCompetidores.sort(sortByClass);
        nuevosCompetidores = nuevosCompetidores.sort(sortByRating);
        nuevosCompetidores = nuevosCompetidores.sort(sortBySerie);
        setCompetidores(nuevosCompetidores)
    }


    var rows = userBoat !== null ? competidores.map(rating => {
        var r1 = getTCF(userBoat[esConSpinnaker ? "rating" : "ratingSs"]);
        var r2 = getTCF(rating[esConSpinnaker ? "rating" : "ratingSs"]);
        var ret = {
            ...rating,
            diferencia: (r1 - r2) * 3600.0 / r2
        }
        return ret;
    }) : [];

    const onRowsUpdated = (index) => {
        var newBoats = [...rows];
        newBoats.splice(index, 1);
        setCompetidores(newBoats)
    }    

    return (<>

    <Grid container  spacing={3}>

        <Grid item xs={12} className="no-print">
            <Panel>
                <H2>Comparador de tiempos</H2>
                <p>Primero indique cual es su embarcacion, despues agregue otras. Podra ver cuanto tiempo debe (o le deben) descontar otras embarcaciones por hora navegada.</p>
            </Panel>
        </Grid>

        <Grid item xs={12}  className="no-print">
            <Panel className="no-print">
                <Grid container  spacing={3} >
                
                    {/* {!userBoat && ( */}
                        <GridOneQuarter className="no-print">
                            <FindBoatInput 
                                id="boat-reference"
                                nextElementWithFocus="boat-to-compare"
                                onBoatFound={(boat) => setUserBoat(boat)} 
                                label="Barco de referencia"
                                cleanOnSelected={false}

                                />
                        </GridOneQuarter>
                    {/* )} */}
                    <GridOneQuarter className="no-print">
                            <SelectInput id="tipoRating" label="Tipo de rating" variant="outlined" value={esConSpinnaker} onChange={(event) => setEsConSpinnaker(event.target.value)}>
                                <MenuItem value={true}>Con Spinnaker</MenuItem>
                                <MenuItem value={false}>Sin Spinnaker</MenuItem>
                            </SelectInput>
                        </GridOneQuarter>
                    
                </Grid>
            </Panel>

            
        </Grid>

        {userBoat && (
                        <GridThreeQuarters  className={"print-only"}>
                            <span style={{marginLeft:10}}>
                                {userBoat.name} - Vela Nº {userBoat.sail} - {userBoat.class} - Serie {userBoat.serie} - {esConSpinnaker?"Con":"Sin"} Spinnaker
                            </span>
                        </GridThreeQuarters>
                    )}

    {userBoat && (<>

            <Grid item xs={12}  className="no-print">
                <Panel>
                    <Grid container  spacing={3}>
                        <GridOneQuarter>
                            <FindBoatInput 
                                id="boat-to-compare"
                                nextElementWithFocus="boat-to-compare"
                                onBoatFound={agregarCompetidor} 
                                label="Barco a comparar"
                                cleanOnSelected={true}
                                />
                        </GridOneQuarter>
                        
                    </Grid>
                </Panel>
            </Grid>

            <Grid item xs={12}>
                <PagoPagaTable rows={rows} onRowsUpdated={onRowsUpdated} esConSpinnaker={esConSpinnaker}/>
            </Grid>
        </>)}
    </Grid>
    </>)
}


function PagoPagaTable({rows, onRowsUpdated, esConSpinnaker=true}){

    const removeBoat = (index) => () => {
        if(typeof(onRowsUpdated) === "function"){
            onRowsUpdated(index);
        }
    };

    const classes = useStyles();
    console.log("Clases:", classes)
    return (<TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Embaración</TableCell>
              <TableCell className={classes.hiddenInXs}>Tipo</TableCell>
              <TableCell className={[classes.hiddenInXs,"no-print"].join(" ")}>Rating / TCF</TableCell>
              <TableCell className={[classes.hiddenInXs, "force-print"].join(" ")}>Serie</TableCell>
              <TableCell>Vela</TableCell>
              <TableCell align="right">1 H</TableCell>
              <TableCell className={[classes.hiddenInXs,"no-print"].join(" ")} align="right">2 Hs</TableCell>
              <TableCell className={[classes.hiddenInXs,"no-print"].join(" ")} align="right">5 Hs</TableCell>
              <TableCell className={[classes.hiddenInXs, "force-print"].join(" ")} align="right">10 Hs</TableCell>
              <TableCell className={[classes.hiddenInXs, "force-print"].join(" ")} align="right">24 Hs</TableCell>
              <TableCell  className={[classes.hiddenInXs,"no-print"].join(" ")} align="right"><IconButton onClick={() => window.print()}><PrintIcon /></IconButton></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell className={classes.hiddenInXs}>{row.class}</TableCell>
                <TableCell className={[classes.hiddenInXs,"no-print"].join(" ")}>{row[esConSpinnaker ? "rating" : "ratingSs"]} / {getTCF(row[esConSpinnaker ? "rating" : "ratingSs"]).toFixed(4)}</TableCell>
                <TableCell className={[classes.hiddenInXs, "force-print"].join(" ")}>{row.serie}</TableCell>
                <TableCell>{row.sail}</TableCell>
                <TableCell align="right">{formatTime(row.diferencia)}</TableCell>
                <TableCell className={[classes.hiddenInXs,"no-print"].join(" ")} align="right">{formatTime(row.diferencia * 2)}</TableCell>
                <TableCell className={[classes.hiddenInXs,"no-print"].join(" ")} align="right">{formatTime(row.diferencia * 5)}</TableCell>
                <TableCell className={[classes.hiddenInXs, "force-print"].join(" ")} align="right">{formatTime(row.diferencia * 10)}</TableCell>
                <TableCell className={[classes.hiddenInXs, "force-print"].join(" ")} align="right">{formatTime(row.diferencia * 24)}</TableCell>
                <TableCell className={[classes.hiddenInXs,"no-print"].join(" ")} align="right"><IconButton onClick={removeBoat(i)}><CloseIcon /></IconButton></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)
}

function formatTime(seconds){
    return `${seconds > 0 ? "+" : seconds < 0 ? "-" : ""}${to2D(seconds / 3600)}:${to2D((seconds / 60) % 60)}:${to2D(seconds % 60)}`
}

function to2D(number){
    number = parseInt(number);
    number = Math.abs(number);
    return number < 10 ? `0${number}`:number;
}