import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { DashboardPage } from './Pages/DashboardPage';
import { PagoPagaPage } from './Pages/PagoPagaPage';
import RatingCalcPage from './Pages/RatingCalcPage';
import TerminosCondicionesPage from './Pages/TerminosCondicionesPage';

export function MainNavigationSwitch(){

    return (<>
        
            <Switch>
                <Route exact={true} path="/" component={DashboardPage} />
                <Route exact={true} path="/calcula-rating" component={RatingCalcPage} />
                <Route exact={true} path="/comparaciones" component={PagoPagaPage} />
                <Route exact={true} path="/terminos-condiciones" component={TerminosCondicionesPage} />
                <Route exact={true} path="*">
                    <Redirect
                        to={{
                        pathname: "/",
                        
                        }}
                    />
                </Route>
            </Switch>
        
    </>)
}