import * as React from 'react';
import { H2 } from '../HTML/Heading';
import {  Grid } from '@mui/material';
import Panel from '../Panel';
import jwt_decode from "jwt-decode";
import { AppContext } from '../../providers/AppContext';

const GOOGLE_CLIENT_ID = "555484025958-6q999kn7d7f9uf0520f1oc7crh8enr6u.apps.googleusercontent.com";
export default function AuthRatings(props)
{
    const {children} = props;
    const {user, setUser} = React.useContext(AppContext);
    console.log(user);
    React.useEffect(() => {
        /* global google */
        console.log("Initializing google")
        google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback:handleGoogleCallback
        });

        google.accounts.id.renderButton(
            document.getElementById("googleSignInDiv"),
            { theme: "outline", size: "large"}
        );
    }, [user]);

    const handleGoogleCallback = (response) => {
        if(response && response.credential ){
            var userObj = jwt_decode(response.credential)
            console.log("user",userObj);
            setUser({
                name:userObj.name,
                email:userObj.email,
                picture:userObj.picture
            });
        }else{
            console.log(response)
        }
        
    };
    return <>
        {user === false && <>
            <Grid item xs={12}>
                <Panel>
                    <H2>Calculadora de ratings</H2>
                    <p>Antes de utilizar la calculadora de ratings, debes inicial sesión y aceptar los terminos, condiciones y politicas de privacidad.</p>
                    <p>Para iniciar sesión, utiliza cualquiera de las opciones disponibles a continuacion:</p>
                    <div className="container" style={{marginLeft:25}}>
                        <div className="row">
                            <div className="col-xs-1">
                                <div id="googleSignInDiv" style={{maxWidth:300}}></div>
                            </div>
                        </div>
                    </div>
                    
                </Panel>
            </Grid>
        </>}
        {user !== false && <>{children}</>}             
    </>

}