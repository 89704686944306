import * as React from 'react';


export default function TextoLegal(){

    return <>
    <h3>Terminos, condiciones y responsabilidad</h3>
        <p style={{fontSize:"0.8em"}}>
            Al utilizar esta página web, usted lo hace bajo su propia responsabilidad y riesgos. 
            Las herramientas disponibles en esta página son de uso libre y gratuito y no cuentan con ningún tipo de garantía. <br/>
            Los autores de esta pagina web se desligan de todo tipo de responsabilidad, incluida civil y penal, por cualquier resultado obtenido o uso indebido, como así tambien de cualquier uso que se le den a las herramientas aquí disponibles.<br/>
            La implementación que se realiza en esta página de los calculos básicos para obtener el rating de una embarcación en la fóruma PHRF, siguiendo las instrucciones que se indican en el reglamento de dicha fórmula, el cual es de acceso público y que puede ser encontrado en la página web de la <a href="https://www.cic.org.ar">Comision Inter Clubes</a>, es de caracter académico y no tiene como fin ningún objetivo comercial ni especulativo, estando prohibida tanto la venta de información obtenida desde esta página web a terceros, como también su uso para cualquier actividad con fines comerciales.<br/>
        </p>
    </>
}